import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import HeaderMain from '../components/HeaderMain';
import HeroSingle from '../components/pages/HeroSingle';
import Footer from '../components/Footer';
import LineFull from '../images/linefull.jpg';
import SEO from '../components/seo';
import SidebarCategories from '../components/sidebar/Categories';
import Search from '../components/sidebar/Search';
import Archives from '../components/sidebar/Archives';
import Schedule from '../components/sidebar/Schedule';
import Shop from '../components/sidebar/Shop';
import '../styles/app.scss';

const blog = ({ data }) => {
    const { wordpressPage: post } = data;
    let test_title = post.yoast_title;
    let new_seo_title = test_title.replace("&#039;", "'");    
    const { allWordpressPost: articles } = data;
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                // description={post.excerpt}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle="Read Our Blog" />

            <section className="page-section">
                <div className="container blogwrapper">
                    <div className="bloglftwrap">
                        {articles.nodes.map(article => (
                            <div className="bloglft" key={article.id}>
                                <div className="blogimg">
                                    <Img
                                        fluid={
                                            article.featured_media.localFile
                                                .childImageSharp.fluid
                                        }
                                    />
                                </div>
                                <div className="bloxexc">
                                    <Link
                                        to={`/${article.slug}`}
                                        className="postName"
                                    >
                                        <h2
                                            dangerouslySetInnerHTML={{
                                                __html: article.title,
                                            }}
                                        />
                                    </Link>
                                    <h5>
                                        {
                                            months[
                                                new Date(
                                                    article.date
                                                ).getMonth()
                                            ]
                                        }{' '}
                                        {new Date(article.date).getDate()},
                                        {new Date(article.date).getFullYear()}
                                    </h5>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: article.excerpt,
                                        }}
                                    />

                                    <Link to={`/${article.slug}`}>
                                        Read More
                                    </Link>
                                </div>
                                <img
                                    className="blogline"
                                    src={LineFull}
                                    alt="linefull"
                                />
                            </div>
                        ))}
                    </div>

                    <div className="blogsidebar">
                        <SidebarCategories />
                        <Search />
                        <Archives />
                        <Schedule />
                 {/*       <Shop />    */}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};
export default blog;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            content
            yoast_meta {
                name
                property
            }
            yoast_title
             yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
        allWordpressPost {
            nodes {
                id
                slug
                categories {
                    name
                    slug
                }
                excerpt
                title
                date
                featured_media {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
            }
        }
    }
`;
